<template>
  <div v-if="currentRole === 4 || currentRole === 5" class="book-inventory mt-5">
    <h1 class="heading">Inventory Order Detail by PO</h1>
    <div class="d-flex mt-4 ml-0" style="gap: 10px">
      <Autocomplete
        @setFilter="setFilter"
        :styleBorder="'out-lined'"
        placeholder="Select..."
        :keyFilter="header.value"
        :selectedInit="filters['location']"
        :listItem="header.items"
        :announLabel="header.announLabel"
      />
      <v-select class="select-item" v-model="month" :items="listMonth" dense label="Month" outlined>
      </v-select>
      <v-select class="select-item" v-model="year" :items="listYear" dense label="Year" outlined>
      </v-select>
      <v-btn color="primary" @click="SearchData">Search</v-btn>
    </div>
    <template v-if="isLoading">
      <div class="pa-6">
        <v-progress-linear indeterminate color="primary"></v-progress-linear>
      </div>
    </template>
    <v-data-table
    v-if="!isLoading"
      :headers="rootHeaders"
      :items="filtered"
      :items-per-page="1000000"
      hide-default-header
      hide-default-footer
      class="elevation-0 mb-5 bookTable"
      fixed-header
      height="79vh"
      id="bookTable"
    >
      <template v-slot:header="{}">
        <thead style="border-radius: 20px; text-align: center" class="v-data-table-header">
          <tr>
            <th
              v-for="(header, index) in headers"
              :key="index"
              style="
                background: #0b2585;
                color: #fff;
                text-align: center;
                border: 1px solid #dae3e8;
              "
              :colspan="header.child.length === 0 ? 1 : header.child.length"
              :rowspan="header.child.length === 0 ? 2 : 1"
              v-html="header.text"
            ></th>
          </tr>
          <tr>
            <template v-for="(header, index) in headers">
              <th
                :key="index + headerChild"
                v-for="headerChild in header.child"
                style="height: 48px !important; text-align: center; border: 1px solid #dae3e8"
                v-html="headerChild"
              ></th>
            </template>
          </tr>
        </thead>
        <tr class="fixed-book-filter">
          <th v-for="header in rootHeaders" :key="header.text">
            <AutoFilter
              v-if="autoFilters.hasOwnProperty(header.value)"
              @setFilter="setAutoFilter"
              :styleBorder="'border-bottom'"
              :keyFilter="header.value"
              :selectedInit="autoFilters[header.value]"
              :listItem="groupColumnValueList(header.value)"
            />
          </th>
        </tr>
      </template>
      <template v-slot:body="{ items }">
        <tbody v-if="items.length === 0" style="text-align: center">
          <div class="pt-3" style="position: absolute; left: 50%; transform: translate(-50%, 0)">
            No data available
          </div>
        </tbody>
        <tbody v-for="(item, index) in filteredTotal" :key="index">
          <tr class="row-overall">
            <td colspan="5" style="text-align: left !important">
              <v-icon class="mr-5" @click="changeHiddenSup(item.locationID)">{{
                listHidden.includes(item.locationID) ? 'mdi-chevron-up' : 'mdi-chevron-down'
              }}</v-icon>
              <p>Center:</p>
              {{ item.location }}
            </td>
            <td>
              <p>
                {{ item.overallTotQtyOrd }}
              </p>
            </td>
            <td>
              <p>
                {{ item.overallActualQty }}
              </p>
            </td>
            <td colspan="1"></td>
            <td>
              <p>
                {{ item.overallTranAmt.toLocaleString() }}
              </p>
            </td>
            <td>
              <p>
                {{ item.overallQtyRcvd }}
              </p>
            </td>
            <td>
              <p>
                {{ item.overallRcptQty }}
              </p>
            </td>
            <td>
              <p>
                {{ item.overallTransfInQty }}
              </p>
            </td>
            <td>
              <p>
                {{ item.overallTransfInTranAmt }}
              </p>
            </td>
            <td>
              <p>
                {{ item.overallTransfOutQty }}
              </p>
            </td>
            <td>
              <p>
                {{ item.overallTransfOutTranAmt }}
              </p>
            </td>
            <td>
              <p>
                {{ item.overallReturnQty }}
              </p>
            </td>
            <td>
              <p>
                {{ item.overallReturnTranAnt }}
              </p>
            </td>
            <td>
              <p>
                {{ item.overallRemainQty }}
              </p>
            </td>
          </tr>
          <tr class="row-sale" v-for="(item2, index) in item.purchaseOrd" :key="index">
            <td v-if="listHidden.includes(item2.locationID) === true">
              {{ index + 1 }}
            </td>
            <td v-if="listHidden.includes(item2.locationID) === true">
              {{ item2.purOrdID }}
            </td>
            <td v-if="listHidden.includes(item2.locationID) === true">
              {{ item2.vendorCode }}
            </td>
            <td v-if="listHidden.includes(item2.locationID) === true">
              {{ item2.productCode }}
            </td>
            <td v-if="listHidden.includes(item2.locationID) === true">
              {{ item2.description }}
            </td>
            <td v-if="listHidden.includes(item2.locationID) === true">
              {{ item2.totQtyOrd }}
            </td>
            <td v-if="listHidden.includes(item2.locationID) === true">
              {{ item2.actualQty }}
            </td>
            <td v-if="listHidden.includes(item2.locationID) === true">
              {{ item2.unitCost.toLocaleString() }}
            </td>
            <td v-if="listHidden.includes(item2.locationID) === true">
              {{ item2.tranAmt.toLocaleString() }}
            </td>
            <td v-if="listHidden.includes(item2.locationID) === true">
              {{ item2.qtyRcvd }}
            </td>
            <td v-if="listHidden.includes(item2.locationID) === true">
              {{ item2.rcptQty }}
            </td>
            <td v-if="listHidden.includes(item2.locationID) === true">
              {{ item2.transfInQty }}
            </td>
            <td v-if="listHidden.includes(item2.locationID) === true">
              {{ item2.transfInTranAmt }}
            </td>
            <td v-if="listHidden.includes(item2.locationID) === true">
              {{ item2.transfOutQty }}
            </td>
            <td v-if="listHidden.includes(item2.locationID) === true">
              {{ item2.transfOutTranAmt }}
            </td>
            <td v-if="listHidden.includes(item2.locationID) === true">
              {{ item2.returnQty }}
            </td>
            <td v-if="listHidden.includes(item2.locationID) === true">
              {{ item2.returnTranAnt }}
            </td>
            <td v-if="listHidden.includes(item2.locationID) === true">
              {{ item2.remainQty }}
            </td>
          </tr>
        </tbody>
        <tfoot v-if="items.length > 0">
          <tr class="bookTableFoot">
            <td class="text-h6" colspan="5" style="text-align: left !important">Grand Total</td>
            <td>
              {{ filteredPayment.overallTotQtyOrd.toLocaleString() }}
            </td>
            <td>
              {{ filteredPayment.overallActualQty.toLocaleString() }}
            </td>
            <td></td>
            <td>
              {{ filteredPayment.overallTranAmt.toLocaleString() }}
            </td>
            <td>
              {{ filteredPayment.overallQtyRcvd.toLocaleString() }}
            </td>
            <td>
              {{ filteredPayment.overallRcptQty.toLocaleString() }}
            </td>
            <td>
              {{ filteredPayment.overallTransfInQty.toLocaleString() }}
            </td>
            <td>
              {{ filteredPayment.overallTransfInTranAmt.toLocaleString() }}
            </td>
            <td>
              {{ filteredPayment.overallTransfOutQty.toLocaleString() }}
            </td>
            <td>
              {{ filteredPayment.overallTransfOutTranAmt.toLocaleString() }}
            </td>
            <td>
              {{ filteredPayment.overallReturnQty.toLocaleString() }}
            </td>
            <td>
              {{ filteredPayment.overallReturnTranAnt.toLocaleString() }}
            </td>
            <td>
              {{ filteredPayment.overallRemainQty.toLocaleString() }}
            </td>
          </tr>
        </tfoot>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { book_management } from '@/api/book_management'
import Autocomplete from '@/components/Utils/AutocompleteAnnoncement.vue'
import AutoFilter from '@/components/Utils/Autocomplete.vue'

export default {
  components: {
    Autocomplete,
    AutoFilter,
  },
  setup() {
    return {}
  },
  data() {
    return {
      isLoading: false,
      header: {},
      currentRole: JSON.parse(localStorage.getItem('currentUser')).userInfo.accountTypeID,
      month: 0,
      year: 0,
      filters: {
        location: [],
      },
      listLocation: [],
      listYear: [],
      listMonth: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      listInventory: [],

      //
      autoFilters: {
        purOrdID: [],
        vendorCode: [],
        productCode: [],
        description: [],
        totQtyOrd: [],
        actualQty: [],
        unitCost: [],
        tranAmt: [],
        qtyRcvd: [],
        rcptQty: [],
        transfInQty: [],
        transfInTranAmt: [],
        transfOutQty: [],
        transfOutTranAmt: [],
        returnQty: [],
        returnTranAnt: [],
        remainQty: [],
      },
      //setting table
      headers: [
        { text: 'No', child: [] },
        { text: 'PO Nbr', child: [] },
        { text: 'Vendor', child: [] },
        { text: 'Product Code', child: [] },
        { text: 'Description', child: [] },
        { text: 'Total Qty Ord', child: [] },
        { text: 'PO', child: ['Qty Order Actual', 'Unit Price', 'Order Amount'] },
        { text: 'PO Receipt', child: ['Received Qty', 'Received Amount'] },
        { text: 'Transfer IN', child: ['Transfer IN Qty', 'Transfer IN Amount'] },
        {
          text: 'Transfer OUT',
          child: ['Transfer Out Qty', 'Transfer Out Amount'],
        },
        { text: 'Return', child: ['Return Qty', 'Return Amount'] },
        { text: 'Remain Qty', child: [] },
      ],
      rootHeaders: [
        { text: 'No', align: 'center', value: 'no', sortable: false },
        { text: 'PO Nbr', value: 'purOrdID', align: 'center', sortable: false },
        {
          text: 'Vendor',
          value: 'vendorCode',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Product Code',
          value: 'productCode',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Description',
          value: 'description',
          align: 'center',
          sortable: false,
        },
        { text: 'Total Qty Ord', value: 'totQtyOrd', align: 'center', sortable: false },
        { text: 'Qty Order Actual', value: 'actualQty', align: 'center', sortable: false },
        {
          text: 'Unit Price',
          value: 'unitCost',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Order Amount',
          value: 'tranAmt',
          align: 'center',
          sortable: false,
        },
        { text: 'Received Qty', value: 'qtyRcvd', align: 'center', sortable: false },
        {
          text: 'Received Amount',
          value: 'rcptQty',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Transfer IN Qty',
          value: 'transfInQty',
          align: 'center',
          sortable: false,
        },
        { text: 'Transfer IN Amount', value: 'transfInTranAmt', align: 'center', sortable: false },
        { text: 'Transfer Out Qty', value: 'transfOutQty', align: 'center', sortable: false },
        {
          text: 'Transfer Out Amount',
          value: 'transfOutTranAmt',
          align: 'center',
          sortable: false,
        },
        { text: 'Return Qty', value: 'returnQty', align: 'center', sortable: false },
        { text: 'Return Amount', value: 'returnTranAnt', align: 'center', sortable: false },
        { text: 'Remain Qty', value: 'remainQty', align: 'center', sortable: false },
      ],
      listHidden: [],
    }
  },
  computed: {
    filteredTotal() {
      return this.filtered.map(el => {
        return {
          ...el,
          overallTotQtyOrd: el.purchaseOrd.reduce((acc, cur) => acc + cur.totQtyOrd, 0),
          overallActualQty: el.purchaseOrd.reduce((acc, cur) => acc + cur.actualQty, 0),
          overallTranAmt: el.purchaseOrd.reduce((acc, cur) => acc + cur.tranAmt, 0),
          overallQtyRcvd: el.purchaseOrd.reduce((acc, cur) => acc + cur.qtyRcvd, 0),
          overallRcptQty: el.purchaseOrd.reduce((acc, cur) => acc + cur.rcptQty, 0),
          overallTransfInQty: el.purchaseOrd.reduce((acc, cur) => acc + cur.transfInQty, 0),
          overallTransfInTranAmt: el.purchaseOrd.reduce((acc, cur) => acc + cur.transfInTranAmt, 0),
          overallTransfOutQty: el.purchaseOrd.reduce((acc, cur) => acc + cur.transfOutQty, 0),
          overallTransfOutTranAmt: el.purchaseOrd.reduce(
            (acc, cur) => acc + cur.transfOutTranAmt,
            0
          ),
          overallReturnQty: el.purchaseOrd.reduce((acc, cur) => acc + cur.returnQty, 0),
          overallReturnTranAnt: el.purchaseOrd.reduce((acc, cur) => acc + cur.returnTranAnt, 0),
          overallRemainQty: el.purchaseOrd.reduce((acc, cur) => acc + cur.remainQty, 0),
        }
      })
    },
    filteredPayment() {
      let grandTotal = {
        overallTotQtyOrd: 0,
        overallActualQty: 0,
        overallTranAmt: 0,
        overallQtyRcvd: 0,
        overallRcptQty: 0,
        overallTransfInQty: 0,
        overallTransfInTranAmt: 0,
        overallTransfOutQty: 0,
        overallTransfOutTranAmt: 0,
        overallReturnQty: 0,
        overallReturnTranAnt: 0,
        overallRemainQty: 0,
      }
      this.filtered.forEach(el =>
        el.purchaseOrd.forEach(d => {
          grandTotal.overallTotQtyOrd += d.totQtyOrd
          grandTotal.overallActualQty += d.actualQty
          grandTotal.overallTranAmt += d.tranAmt
          grandTotal.overallQtyRcvd += d.qtyRcvd
          grandTotal.overallRcptQty += d.rcptQty
          grandTotal.overallTransfInQty += d.transfInQty
          grandTotal.overallTransfInTranAmt += d.transfInTranAmt
          grandTotal.overallTransfOutQty += d.transfOutQty
          grandTotal.overallTransfOutTranAmt += d.transfOutTranAmt
          grandTotal.overallReturnQty += d.returnQty
          grandTotal.overallReturnTranAnt += d.returnTranAnt
          grandTotal.overallRemainQty += d.remainQty
        })
      )
      grandTotal.overallTotQtyOrd = Number(grandTotal.overallTotQtyOrd.toFixed(0))
      grandTotal.overallActualQty = Number(grandTotal.overallActualQty.toFixed(0))
      grandTotal.overallTranAmt = Number(grandTotal.overallTranAmt.toFixed(0))
      grandTotal.overallQtyRcvd = Number(grandTotal.overallQtyRcvd.toFixed(0))
      grandTotal.overallRcptQty = Number(grandTotal.overallRcptQty.toFixed(0))
      grandTotal.overallTransfInQty = Number(grandTotal.overallTransfInQty.toFixed(0))
      grandTotal.overallTransfInTranAmt = Number(grandTotal.overallTransfInTranAmt.toFixed(0))
      grandTotal.overallTransfOutQty = Number(grandTotal.overallTransfOutQty.toFixed(0))
      grandTotal.overallTransfOutTranAmt = Number(grandTotal.overallTransfOutTranAmt.toFixed(0))
      grandTotal.overallReturnQty = Number(grandTotal.overallReturnQty.toFixed(0))
      grandTotal.overallReturnTranAnt = Number(grandTotal.overallReturnTranAnt.toFixed(0))
      grandTotal.overallRemainQty = Number(grandTotal.overallRemainQty.toFixed(0))
      return grandTotal
    },
    filtered() {
      return this.listInventory.map(el => {
        return {
          ...el,
          purchaseOrd: el.purchaseOrd.filter(d => {
            return Object.keys(this.autoFilters).every(f => {
              return this.autoFilters[f].length < 1 || this.autoFilters[f].includes(d[f])
            })
          }),
        }
      })
    },
  },
  created() {
    this.getCenter()
    this.getMonthYear()
    this.init()
  },
  methods: {
    SearchData() {
      this.init()
    },
    changeHiddenSup(subItem) {
      if (this.listHidden.includes(subItem)) {
        this.listHidden = this.listHidden.filter(function (item) {
          return item !== subItem
        })
      } else {
        this.listHidden = [...this.listHidden, subItem]
      }
    },
    /**
     * ? config filter
     */
    setAutoFilter(objectFilterChange) {
      this.autoFilters = {
        ...this.autoFilters,
        [objectFilterChange.name]: objectFilterChange.filter,
      }
    },
    groupColumnValueList(val) {
      let columnVal = []
      this.listInventory.forEach(el => {
        columnVal = [...columnVal, ...el.purchaseOrd]
      })
      return [...new Set(columnVal.map(d => d[val]))]
    },
    /**
     * ? config center autocomplete
     */
    getCenter() {
      this.listLocation = JSON.parse(localStorage.getItem('currentUser')).locationPermissions
      const location = this.getIdArray(
        this.listLocation,
        [parseInt(localStorage.getItem('currentCenter'))],
        'locationID',
        'location'
      )
      this.filters.location = location
      this.header = {
        announLabel: 'Center',
        value: 'location',
        items: this.listLocation.map(el => el.location),
      }
    },
    getIdArray(arr1, arr2, name, id) {
      return arr1.filter(item => arr2.includes(item[name])).map(item => item[id])
    },
    setFilter(objectFilterChange) {
      this.filters = {
        ...this.filters,
        [objectFilterChange.name]: objectFilterChange.filter,
      }
    },

    /**
     * ? Configure set month and Year
     */
    getMonthYear() {
      this.year = new Date().getFullYear()
      this.month = new Date().getMonth() + 1
      for (let i = this.year; i > 2000; i--) {
        this.listYear.push(i)
      }
    },
    async init() {
      this.isLoading = true
      this.listInventory = []
      const locationArr = this.getIdArray(
        this.listLocation,
        this.filters.location,
        'location',
        'locationID'
      )
      const promises = locationArr.map(async el => {
        const res = await book_management.getInventoryDetail(el, this.month, this.year)
        if (res.length > 0) {
          const mergedInfo = []
          res.forEach(element => {
            const { location, locationID } = element
            const existingElement = mergedInfo.find(el => el.locationID === locationID)
            delete element.location
            if (existingElement) {
              existingElement.purchaseOrd.push(element)
            } else {
              const newElement = {
                location,
                locationID,
                purchaseOrd: [element],
              }
              mergedInfo.push(newElement)
            }
          })
          this.listInventory.push(mergedInfo[0])
          this.listInventory.sort((a, b) => a.locationID - b.locationID)
        }
      })
      await Promise.all(promises)
      this.isLoading = false
    },
  },
}
</script>

<style lang="scss" scoped>
.book-inventory h1 {
  font-size: 21px;
}

.select-item {
  // margin-right: 10px;
  max-width: 200px;
}
.multiple-select {
  margin-right: 10px;
  max-width: 400px;
}

.book-inventory {
  margin: 0 20px;
}

.fixed-book-filter {
  position: sticky;
  top: 110px;
  z-index: 2;
  background-color: #ffffff;
}
.row-overall {
  td {
    text-align: center;
    p {
      font-weight: bold;
      display: inline-block;
    }
  }
}
.row-sale {
  background: #f3f3f3;
  td {
    text-align: center;
  }
}
.bookTableFoot {
  td {
    text-align: center;
    font-weight: bold;
  }
}
</style>
